import { createSlice } from "@reduxjs/toolkit";
import { mission3InitialState } from "./initialStates";

const mission3Slice = createSlice({
  name: "mission3",
  initialState: mission3InitialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setTermination: (state, action) => {
      state.termination = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const { setStep, setTermination, setSuccess } = mission3Slice.actions;
export const mission3Reducer =  mission3Slice.reducer;
