import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage  } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyB7YsrsKnPEckImfrgvfQnrHPa_QYfnwDA",
  authDomain: "ind-iusm-chiis-sw-dev.firebaseapp.com",
  projectId: "ind-iusm-chiis-sw-dev",
  storageBucket: "ind-iusm-chiis-sw-dev.appspot.com",
  messagingSenderId: "683622666682",
  appId: "1:683622666682:web:6553880cd1dc1aee5f8d83"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app);

export { auth, storage, firestore, app as default };
