export const mission4InitialState = {
  dedicatedHours: 10,
  sprintDuration: 0,
  EvaluatePath: 0,
  Tasks: [""],
  SprintWork: "",
  SprintType: "",
  NumberOfPeople: 0,
  hours: 0,
  peopleData: Array.from({ length: 0 }, (_, index) => ({
    name: "",
    functionality: "",
    hours: 0,
    tasks: [""],
  })),
};
