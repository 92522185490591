import { createSlice } from "@reduxjs/toolkit";
import * as initialStates from "./initialStates"; 



const authSlice = createSlice({
  name: "auth",
  initialState: initialStates.authInitialState,
  reducers: {
    switchTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setCircleProgressValue: (state, action) => {
      state.circleProgressValue = action.payload;
    },
    setCurrentMission: (state, action) => {
      state.currentMission = action.payload;
    },
    setResetEmailSent: (state, action) => {
      state.resetEmailSent = action.payload;
    },
    setEmailForgot: (state, action) => {
      state.emailreset = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setAllBehaviorId: (state, action) => {
      state.allbehaviorId = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setShowPassword: (state, action) => {
      state.showPassword = action.payload;
    },
    setSignupEmail: (state, action) => {
      state.emailsignup = action.payload;
    },
    setSignupPassword: (state, action) => {
      state.passwordsignup = action.payload;
    },
    setConfirmPassword: (state, action) => {
      state.confirmPassword = action.payload;
    },
    setShowConfirmPassword: (state, action) => {
      state.showConfirmPassword = action.payload;
    },
    setShowSignupPassword: (state, action) => {
      state.showSignupPassword = action.payload;
    },
    setSignupUsername: (state, action) => {
      state.SignupUsername = action.payload;
    },
    
  },
});

export const { switchTab,setCircleProgressValue, setResetEmailSent,setEmailForgot, setUser, setLoading, setEmail, setPassword,setShowPassword, setSignupEmail,  setSignupPassword,  setConfirmPassword,setShowConfirmPassword,setShowSignupPassword, setUserId , setAllBehaviorId,setCurrentMission, setSignupUsername } = authSlice.actions;

export const authReducer = authSlice.reducer;









