import React, { useState, useEffect } from "react";
import { ReactComponent as Barrow } from "../../Assets/Svgs/barrow.svg";

const SprintBlueprintValues = ({ nestedData, beh }) => {
  const [updatedNestedData, setUpdatedNestedData] = useState([]);

  useEffect(() => {
    if (nestedData.length > 0) {
      setUpdatedNestedData(nestedData[0].stakeholders);
    }
  }, [nestedData]);
  return (
    <div className="flex flex-col space-y-8 text-black font-roboto ">
      {updatedNestedData.map((stakeholder) => (
        <div
          key={stakeholder.stakeholderId}
          className=" w-full flex flex-col space-y-7 justify-center items-center bg-primary text-black rounded   "
        >
          <div className="w-[271px] h-[34.16px] text-center text-white text-[25px] font-bold mt-3">
            {stakeholder.Name}
          </div>
          {stakeholder.behaviors.map((behavior) => (
            <div key={behavior.behaviorId} className="space-y-10 w-full p-10">
              {behavior.biases.map((bias) => (
                <div className="w-full max-h-[10000px] flex flex-col space-y-10 bg-neutral-50 rounded-[10px] shadow border border-primary pb-5">
                  <div className="w-full h-[60px] flex flex-row space-x-1 bg-zinc-100 rounded justify-center items-center text-center p-2">
                    <h1 className=" font-semibold ">Behavior : </h1>
                    <h1 className="text-primary font-light ">
                      {behavior.Behavior.substring(0,20) + "..."}
                    </h1>
                    <h1 className="text-primary font-light "> </h1>
                    <h1 className=" font-semibold ">|| Cognitive Bias : </h1>
                    <h1 className="text-primary font-light ">
                      {bias.Category.substring(0,20) + "..."}
                    </h1>

                    <h1 className="text-primary font-light "> </h1>
                    <h1 className=" font-semibold ">|| Nudge : </h1>
                    <h1 className="text-primary font-light ">
                      {bias.AssociatedNudgeType.substring(0,20) + "..."}
                    </h1>
                  </div>
                  <div className="flex flex-row space-x-3">
                    <Barrow className="mt-2" />
                    <div className="flex flex-col space-y-3">
                      <h1 className="text-primary font-bold  ">DESIGN</h1>
                      <div className="">
                        <span className=" font-semibold  ">You are using “</span>
                        <span className=" font-light  ">
                          {" "}
                          {bias.AssociatedNudgeType}
                        </span>
                        <span className=" font-semibold  ">”. “</span>
                        <span className=" font-light  ">{bias.Description}</span>
                        <span className=" font-semibold  ">”.</span>
                      </div>
                      <div className="">
                        <span className=" font-semibold  ">You need to do “</span>

                        <span className=" font-light  ">{bias.Path}</span>
                        <span className=" font-semibold  ">
                          ” to navigate through the Sprint path.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row space-x-3">
                    <Barrow className="mt-2" />
                    <div className="flex flex-col space-y-3">
                      <h1 className="text-primary font-bold  ">RUN</h1>
                      <div className="">
                        <span className=" font-semibold  ">
                          You will evaluate the Sprint’s path “
                        </span>
                        <span className=" font-light ">{beh.EvaluatePath}</span>
                        <span className=" font-semibold  ">
                          ” times per month. To do so, you will evaluate this
                          person for the below task(s): “
                        </span>
                        <span className="font-light">
                          {beh.Tasks.join(", ")}
                        </span>
                        <span className=" font-semibold  ">”</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row space-x-3">
                    <Barrow className="mt-2" />
                    <div className="flex flex-col space-y-3">
                      <h1 className="text-primary font-bold  ">MONITOR</h1>
                      <div className="flex flex-row space-x-2">
                        <h1 className=" font-semibold  ">
                          You will collect data from “
                        </h1>
                        <h1 className=" font-light  ">{beh.EvaluatePull}</h1>
                        <h1 className=" font-semibold  ">”, “</h1>
                        <h1 className=" font-light  ">
                          {beh.DataSources.join(", ")}
                        </h1>
                        <h1 className=" font-semibold  ">” times per month.</h1>
                      </div>
                      <div className="">
                        <h1 className=" font-semibold  ">
                          The GOAL of the Sprint is to ensure timely completion
                          of the task(s) in preparation for the evaluation
                          deadline.
                        </h1>
                      </div>
                      <div className="">
                        <span className=" font-normal  ">
                          The RESULT of the Sprint would be{" "}
                        </span>

                        <span className=" font-semibold  ">
                          {" "}
                          addressing and resolving{" "}
                        </span>
                        <span className=" font-normal  ">“</span>
                        <span className=" font-light  ">{beh.Overview}</span>
                        <span className=" font-normal  ">“.</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row space-x-3">
                    <Barrow className="mt-2" />
                    <div className="flex flex-col space-y-3">
                      <h1 className="text-primary font-bold  ">MSOP</h1>
                      <div className="">
                        <span className=" font-semibold  ">
                          The Effective Minimally Viable Nudge for this Nudge is
                          “
                        </span>
                        <span className=" font-light  ">{bias.MSOP}</span>
                        <span className=" font-semibold  ">” .</span>
                      </div>
                      <div className="">
                        <span className=" font-semibold  ">
                          Alternative plans if the nudge was not effective: “
                        </span>
                        <span className=" font-light  ">{bias.Next}</span>
                        <span className=" font-semibold  ">”.</span>
                      </div>
                      <div className="">
                        <span className=" font-semibold  ">
                          The nudge will be scaled and standardized: "
                        </span>
                        <span className=" font-light  ">{bias.Scale}</span>
                        <span className=" font-semibold  ">”.</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SprintBlueprintValues;
