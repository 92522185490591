import { createSlice } from "@reduxjs/toolkit";
import { mission2InitialState } from "./initialStates";

const mission2Slice = createSlice({
  name: "mission2",
  initialState: mission2InitialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setStakeholders: (state, action) => {
      state.stakeholders = action.payload;
    },
  },
});

export const { setStep, setStakeholders } = mission2Slice.actions;
export const mission2Reducer =  mission2Slice.reducer;
