import { createSlice } from "@reduxjs/toolkit";
import { mission5InitialState } from "./initialStates";

const mission5Slice = createSlice({
  name: "mission5",
  initialState: mission5InitialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setDataSources: (state, action) => {
      state.dataSources = action.payload;
    },
    setEvaluatePull: (state, action) => {
      state.evualuatepull = action.payload;
    },
  },
});

export const { setStep, setDataSources, setEvaluatePull } = mission5Slice.actions;
export const mission5Reducer =  mission5Slice.reducer;
