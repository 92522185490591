import React from "react";
import { ReactComponent as HomeIcon } from "../Assets/Svgs/home.svg";
import { ReactComponent as BehaviorsIcon } from "../Assets/Svgs/behaviors.svg";
import { ReactComponent as GrowthIcon } from "../Assets/Svgs/growth.svg";
import { ReactComponent as NetworkIcon } from "../Assets/Svgs/network.svg";

import { NavLink, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

import { Menu, MenuItem, ListItemIcon, IconButton } from "@mui/material";
import { Logout, Settings } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { connect } from "react-redux";
import { GET_USER_BY_EMAIL } from "../Graphql/User/Query";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "../Components/Common/LoadingIndicator";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  
  const { loading: queryLoading, data: userData } = useQuery(
    GET_USER_BY_EMAIL,
    {
      variables: { email : localStorage.getItem('email')},
    }
  );

if (queryLoading) {
  return <LoadingIndicator />;
}


  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const handleProfile = async () => {
  navigate("/profile");
  };
  const handleAdmin = async () => {
    navigate("/admin");
    };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="flex flex-row p-5  w-screen xs:text-xs sm:text-sm md:text-md lg:text-lg lg:h-[100px]  md:h-[5rem] sm:h-[4rem] xs:h-[3rem] bg-white rounded-sm shadow-inner font-roboto shadow-gray-500">
      <div className="flex flex-row w-screen  space-x-3 justify-between items-center lg:ml-7 lg:mr-5">
        <a href="/" className="lg:w-[162.64px] lg:h-4 lg:mb-8">
          <img
            className="lg:w-[106px] lg:h-[43px] xs:w-10 xs:h-5"
            alt=""
            src="https://d35wha680fyhmr.cloudfront.net/logo.webp"
          />
        </a>
        <NavItem itemName="home" icon={<HomeIcon stroke="#AFAEB2" />} />
        <NavItem
          itemName="behaviors"
          icon={<BehaviorsIcon stroke="#AFAEB2" />}
        />
        <NavItem itemName="growth" icon={<GrowthIcon />} />
        <NavItem itemName="network" icon={<NetworkIcon />} />
        <div className="flex flex-row  space-x-3 items-center">
          <div className="flex flex-row justify-between items-center">
            <img
              src="https://d35wha680fyhmr.cloudfront.net/profile.webp"
              alt=""
              className="rounded-full lg:h-12 lg:w-12 xs:w-7 xs:h-7 "
            />
           
              <h1 className="ml-2 max-w-[100px]  text-[#141414] text-opacity-50  mt-1  mr-2">
              {userData.getUserByEmail.username.length > 8
    ? `${userData.getUserByEmail.username.substring(0, 8)}.....`
    : userData.getUserByEmail.username}
              </h1>
      
            <IconButton
              onClick={handleClick}
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                marginTop: "14px",
                color : "#141414",
                opacity : "50%"
              }}
            >
              <KeyboardArrowDownIcon className="mb-2 " />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
            >
              <MenuItem>
                <h2 className="text-primary ">
                  Hello! <br />
                  {userData.getUserByEmail.username}
                </h2>
              </MenuItem>
              {/* <MenuItem onClick={handleProfile}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Profile
              </MenuItem>
              {localStorage.getItem("email") === "vrushab1998@gmail.com" ?   (<div><MenuItem onClick={handleAdmin}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Admin Settings
              </MenuItem></div>) : <div></div>}
            */}
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

const NavItem = ({ itemName, icon }) => {
  const location = useLocation();
  const isActive = location.pathname === `/${itemName}/0`;

  return (
    <NavLink to={`/${itemName}/0`}>
      <div
        className={`flex flex-row  space-x-3 items-center lg:w-[162.64px] lg:h-4 space-x-4 ${
          isActive ? "text-primary" : "text-zinc-400"
        }`}
      >
        {React.cloneElement(icon, {
          stroke: isActive ? "#1171BA" : "#AFAEB2",
        })}
        <h1>{itemName.charAt(0).toUpperCase() + itemName.slice(1)}</h1>
      </div>
    </NavLink>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Navbar);
