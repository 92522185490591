import React from 'react'

const LoadingIndicator = () => {
  return (
    <div className="w-screen h-screen bg-primary flex justify-center items-center text-4xl text-white">
    Loading...
  </div>
  )
}

export default LoadingIndicator