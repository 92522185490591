
import { configureStore } from '@reduxjs/toolkit';
import { authReducer} from './Common/Slice';
import { mission1Reducer } from './Mission1/Slice';
import { mission3Reducer } from './Mission3/Slice';
import { mission5Reducer } from './Mission5/Slice';
import { mission2Reducer } from './Mission2/Slice';
import { initialReducer } from './Initialsteps/Slice';
import { mission4Reducer } from './Mission4/Slice';

const Store = configureStore({
  reducer: {
    auth: authReducer,
    mission1: mission1Reducer,
    mission2 : mission2Reducer,
    mission3 : mission3Reducer,
    mission4 : mission4Reducer,
    mission5 : mission5Reducer,
    initial : initialReducer,
   
  },
});

export default Store;
