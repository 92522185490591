import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../Pages/NavBar";
import Sidebar from "./Sidebar";
import { ReactComponent as Print } from "../../Assets/Svgs/printb.svg";
import { ReactComponent as Download } from "../../Assets/Svgs/downloadb.svg";
import { ReactComponent as Share } from "../../Assets/Svgs/share.svg";
import ReactToPrint from "react-to-print";
import SprintBlueprintValues from "./SprintBlueprintValues";
import generatePDF from "react-to-pdf";
import { connect } from "react-redux";
import { GET_ONE_ALL_BEHAVIOR } from "../../Graphql/User/Query";
import { useQuery } from "@apollo/client";
import fetchNestedData from "../../Pages/NestedDataFetcher";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { UPDATE_ALL_BEHAVIORS } from "../../Graphql/User/Mutation";
import { useMutation } from "@apollo/client";
import { storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import LoadingIndicator from "./LoadingIndicator";


const SprintBlueprint = () => {
  const targetRef = useRef();
  const navigate = useNavigate();
  const allBehaviorsId = localStorage.getItem("allBehaviorId");
  const userId = localStorage.getItem("userId");
  const [updateAllBehaviors, { loading }] = useMutation(UPDATE_ALL_BEHAVIORS);
  const [percent, setPercent] = useState(0);
  document.title = "Sprint Blueprint";
  const generateAndUploadPDF = async () => {
    if (!targetRef.current) {
      alert("Content not available for generating PDF");
      return;
    }
    const height = targetRef.current.clientHeight / 5;
    try {
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [270, height],
      });

      const content = targetRef.current;
      const canvas = await html2canvas(content, { scale: 2 });

      const imgData = canvas.toDataURL("image/jpeg");

      pdf.addImage(imgData, "JPEG", 0, 0, 270, height);

      const pdfBlob = pdf.output("blob");

      const storageRef = ref(
        storage,
        `/${userId}/${allBehaviorsId}/SprintBlueprint.pdf`
      );
      const uploadTask = uploadBytesResumable(storageRef, pdfBlob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percentage = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percentage);
        },
        (error) => {
          alert("Upload error:", error);
        },

        () => {
          setPercent(0);
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            updateAllBehaviors({
              variables: {
                userId,
                allBehaviorsId,
                input: { UrlSprint: url },
              },
            })
              .then(({ data }) => {
              
                navigate("/behaviors/m6/3");
              })
              .catch((updateError) => {
                alert("Error updating behaviors:", updateError);
              });
          });
        }
      );
    } catch (error) {
      alert("Error generating PDF:", error);
    }
  };

  const [nestedData, setNestedData] = useState([]);
  const {
    loading: queryLoading,
    data: userData,
    fetchMore,
    refetch,
  } = useQuery(GET_ONE_ALL_BEHAVIOR, {
    variables: { userId, allBehaviorsId },
  });
  useEffect(() => {
    refetch({ userId, allBehaviorsId });
  }, [userId, allBehaviorsId, refetch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!queryLoading && userData) {
          const result = await fetchNestedData({
            userId,
            allBehaviorsId,
            fetchMore,
          });
          setNestedData([{ stakeholders: result }]);
        }
      } catch (error) {
        alert("Error fetching nested data:", error.message);
      }
    };

    fetchData();
  }, [userId, queryLoading, userData, fetchMore, allBehaviorsId]);


  if (queryLoading) {
    return <LoadingIndicator />;
  }
  const beh = userData.getOneAllBehaviors;

  return (
    <div className="flex flex-col  bg-gray-300 font-roboto w-screen">
      <Navbar />
      <div className="flex flex-row">
        <Sidebar value="sprint" />
        <div className="w-[100%] flex flex-col space-y-10 p-10">
          <div className="opacity-50 text-zinc-800 text-lg font-semibold ">
            Behaviors {">"} Create a New Behavior {">"} Create a Sprint {">"}{" "}
            Mission 3 {">"} Agile Nudge SprintBlueprint
          </div>
          <div className="bg-white flex flex-col space-y-5 w-full">
            <div
              ref={targetRef}
              className="w-full rounded-[20px]  flex flex-col space-y-6 justify-center items-center p-10"
            >
              <div className="w-full h-[116px] bg-primary rounded-[20px] flex flex-col space-y-3 justify-center items-center mt-3">
                <div className=" text-center text-white text-[28px] font-semibold ">
                  Agile Sprint Blueprint
                </div>
                <div className="text-black text-base font-light ">
                  (This is the generated SprintBlueprint for your Nudge. You may
                  Save it to your profile as it is suggested or modify it
                  first.)
                </div>
              </div>
              <div className="text-zinc-800 text-xl font-semibold ">
                Your Timeline
              </div>
              <div className="w-full">
                <div className="flex flex-row w-full  rounded-[10px] ">
                  <div className="w-[13%] h-[41px] pl-2.5  py-2.5 bg-primary rounded-[10px] text-white text-sm">
                    Design a Nudge
                  </div>
                  <div className="w-[90%] h-[41px] flex justify-center items-center opacity-30 text-zinc-800 text-sm font-mormal bg-gray-200">
                    Design and Run Sprint
                  </div>
                </div>
                <div className="flex flex-row pb-10 border-b-2">
                  <div className="text-zinc-800 text-sm font-normal ml-5">
                    {(beh.Hours * 2 * 0.1).toFixed(2)} hrs
                  </div>
                  <div className="opacity-30 text-zinc-800 text-sm font-normal ml-[500px]">
                    {(beh.Hours * 2 * 0.9).toFixed(2)} hrs
                  </div>
                </div>
              </div>

              <div class="w-full  text-center">
                {beh.ismultiple ? (
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-col space-y-3 bg-neutral-50 p-3">
                      <span className="text-primary text-xl font-semibold leading-9">
                        This Sprint has "Multiple People" in order to Change "
                        {beh.Title}".
                        <br />
                      </span>
                      <div className="w-full h-[29px] text-center ">
                        <span className="text-black text-base font-semibold]">
                          These “
                        </span>
                        <span className="text-black text-base font-light ">
                          {beh.NumberOfPeople}
                        </span>
                        <span className="text-black text-base font-semibold">
                          ” persons will work “
                        </span>
                        <span className="text-black text-base font-light ">
                          {beh.SprintWork}
                        </span>
                        <span className="text-black text-base font-thin ">
                          {" "}
                        </span>
                        <span className="text-black text-base font-semibold">
                          ” in a “
                        </span>
                        <span className="text-black text-base font-light ">
                          {" "}
                          {beh.SprintType}
                        </span>
                        <span className="text-black text-base font-semibold ">
                          ”.
                        </span>
                      </div>
                    </div>
                    {beh.PeopleDataInput.map((people) => (
                      <div className="flex flex-col space-y-3 bg-neutral-50 p-3 text-start text-black text-base">
                        <div class="w-[956px] h-[38px] text-primary text-xl font-semibold ">
                          "{people.name}"{" "}
                        </div>
                        <div class="w-[982px] ">
                          <span className=" font-semibold leading-loose">
                            Functionality: “
                          </span>
                          <span className=" font-light leading-loose">
                            {people.functionality}
                          </span>
                          <span className=" font-semibold leading-loose">
                            ”.
                            <br />
                            Needs to work “
                          </span>
                          <span className=" font-light leading-loose">
                            {people.hours * 2}
                          </span>
                          <span className=" font-semibold leading-loose">
                            ” to accomplish this Sprint.
                            <br />
                            Task(s):{" "}
                            <span className=" font-light leading-loose">
                              {people.tasks.join(", ")}
                            </span>
                            <br />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col space-y-3 bg-neutral-50 p-3">
                    {" "}
                    <span className="text-primary text-xl font-semibold leading-9">
                      This Sprint has "One Person" in order to Change "
                      {beh.Title}".
                      <br />
                    </span>
                    <div className="w-full h-[29px] text-center pb-10">
                      <span className="text-black text-base font-semibold leading-[25px]">
                        This member needs to dedicate "{beh.HoursWeek / 5}"
                        hours per day in order to accomplish the sprint in "
                        {beh.Days}" days.
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full flex flex-col space-y-10 justify-center bg-grey-50 rounded  pb-10 ">
                <SprintBlueprintValues nestedData={nestedData} beh={beh} />
              </div>
              <div class="w-full p-4 bg-primary bg-opacity-50 rounded-[5px] border border-primary">
                <div class="flex flex-col space-y-5 ">
                  <span className="text-white text-lg font-bold  ">
                    After completing the sprint(s), try to reflect on these 3
                    questions:
                    <br />
                  </span>
                  <div class="w-[100%] h-[103px] bg-white rounded flex p-4  items-center shadow border border-black border-opacity-5">
                    <span className="text-black text-opacity-50 text-base font-medium  leading-[25px]">
                      What did you do that worked well?
                    </span>
                  </div>
                  <div class="w-[100%] h-[103px] bg-white rounded flex p-4  items-center shadow border border-black border-opacity-5">
                    <span className="text-black text-opacity-50 text-base font-medium  leading-[25px]">
                      What did you do that did not work well?
                    </span>
                  </div>
                  <div class="w-[100%] h-[103px] bg-white rounded flex p-4  items-center shadow border border-black border-opacity-5">
                    <span className="text-black text-opacity-50 text-base font-medium  leading-[25px]">
                      What do you plan to do differently based on what you
                      learned?
                    </span>
                  </div>
                </div>
              </div>

              <br />
            </div>
            <div className="w-[full] h-[93px] bg-primary rounded-[20px] flex flex-row justify-between items-center pl-2 pr-2">
              <div className="flex flex-row space-x-5">
                <button
                  className="w-[299px] h-[52px] bg-zinc-800 rounded-[36px] shadow"
                  onClick={generateAndUploadPDF}
                >
                  <div className="text-center text-white text-sm font-semibold ">
                    Save to “Behaviors”
                  </div>
                </button>
                {percent !== 0 ? (
                  <div className="text-xl pt-1">
                    <CircularProgress
                      className="relative top-1"
                      thickness="3"
                      size="25px"
                      color="inherit"
                      variant="determinate"
                      value={percent}
                    />{" "}
                    Uploading..... {percent} %
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="flex flex-row justify-end p-2 items-center space-x-7 pr-10 ">
                <ReactToPrint
                  trigger={() => (
                    <button>
                      <Print />
                    </button>
                  )}
                  content={() => targetRef.current}
                  pageStyle={`
          @page {
            size: A4;
            margin-top: 20mm;
            margin-bottom : 20mm;
          }
          @media print {
            body {
              -webkit-print-color-adjust: exact; /* Chrome, Safari */
              color-adjust: exact; /* Firefox */
            }
            /* Include Tailwind CSS styles for printing */
            @import url('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css');
          }
        `}
                />
                <Share />
                <button
                  onClick={() =>
                    generatePDF(targetRef, { filename: "SprintBlueprint.pdf" })
                  }
                >
                  <Download />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  hours: state.initial.hours,
  termination: state.mission3.termination,
  success: state.mission3.success,
  deadline: state.initial.deadline,
  overview: state.initial.overview,
  userId: state.auth.userId,
  allbehaviorId: state.initial.allbehaviorId,
});

export default connect(mapStateToProps)(SprintBlueprint);
