import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "./Pages/Landing";
import Loggedin from "./Pages/Loggedin";
import SprintBlueprint from "./Components/Common/SprintBlueprint";


// Lazy-loaded components
const Login = lazy(() => import("./Pages/Login"));
const Admin = lazy(() => import("./Pages/Admin"));
const Profile = lazy(() => import("./Pages/Profile"));
const ForgotPassword = lazy(() => import("./Pages/ForgotPassword"));
const Home = lazy(() => import("./Pages/Home"));
const Behaviors = lazy(() => import("./Pages/Behaviors"));
const Initialsteps = lazy(() => import("./Pages/Initialsteps"));
const Mission1 = lazy(() => import("./Pages/Mission1"));
const Mission2 = lazy(() => import("./Pages/Mission2"));
const Mission3 = lazy(() => import("./Pages/mission3"));
const Mission4 = lazy(() => import("./Pages/Mission4"));
const Mission5 = lazy(() => import("./Pages/Mission5"));
const Mission6 = lazy(() => import("./Pages/Mission6"));
const Mission4Single = lazy(() => import("./Pages/Mission4Single"));
const Mission4Multiple = lazy(() => import("./Pages/Mission4Multiple"));
const Blueprint = lazy(() => import("./Components/Common/Blueprint"));
const Growth = lazy(() => import("./Pages/Growth"));
const Network = lazy(() => import("./Pages/Network"));


function App() {
  return (
    <div className="xs:w-xs sm:w-sm md:w-md lg:w-screen bg-primary">
      <section>
        <Routes>
          <Route path="/login" element={<Suspense fallback={<div>Loading...</div>}><Login /></Suspense>} />
          <Route path="/forgotpassword" element={<Suspense fallback={<div>Loading...</div>}><ForgotPassword /></Suspense>} />
          <Route path="/admin" element={<Suspense fallback={<div>Loading...</div>}><Admin /></Suspense>} />
          <Route path="/signup" element={<Landing />} />
          <Route path="/" element={<Loggedin />}>
            <Route path="/profile" element={<Suspense fallback={<div>Loading...</div>}><Profile /></Suspense>} />
          
            <Route path="/" element={<Suspense fallback={<div>Loading...</div>}><Home /></Suspense>} />
            <Route path="/home/0" element={<Suspense fallback={<div>Loading...</div>}><Home /></Suspense>} />
            <Route path="/behaviors" element={<Suspense fallback={<div>Loading...</div>}><Behaviors /></Suspense>} />
            <Route path="/behaviors/:step" element={<Suspense fallback={<div>Loading...</div>}><Initialsteps /></Suspense>} />
            <Route path="/behaviors/m1/:step" element={<Suspense fallback={<div>Loading...</div>}><Mission1 /></Suspense>} />
            <Route path="/behaviors/m2/:step" element={<Suspense fallback={<div>Loading...</div>}><Mission2 /></Suspense>} />
            <Route path="/behaviors/m3/:step" element={<Suspense fallback={<div>Loading...</div>}><Mission3 /></Suspense>} />
            <Route path="/behaviors/m4/:step" element={<Suspense fallback={<div>Loading...</div>}><Mission4 /></Suspense>} />
            <Route path="/behaviors/m4single/:step" element={<Suspense fallback={<div>Loading...</div>}><Mission4Single /></Suspense>} />
            <Route path="/behaviors/m4multiple/:step" element={<Suspense fallback={<div>Loading...</div>}><Mission4Multiple /></Suspense>} />
            <Route path="/behaviors/m5/:step" element={<Suspense fallback={<div>Loading...</div>}><Mission5 /></Suspense>} />
            <Route path="/behaviors/m6/:step" element={<Suspense fallback={<div>Loading...</div>}><Mission6 /></Suspense>} />
            <Route path="/growth/0" element={<Suspense fallback={<div>Loading...</div>}><Growth /></Suspense>} />
            <Route path="/network/0" element={<Suspense fallback={<div>Loading...</div>}><Network /></Suspense>} />
            <Route path="/blueprint" element={<Suspense fallback={<div>Loading...</div>}><Blueprint /></Suspense>} />
            <Route path="/sprintblueprint" element={<Suspense fallback={<div>Loading...</div>}><SprintBlueprint /></Suspense>} />
          </Route>
        </Routes>
      </section>
    </div>
  );
}

export default App;
