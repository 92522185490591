// initialStates.js

export const authInitialState = {
    activeTab: "login",
    circleProgressValue: 0,
    currentMission : 0,
    resetEmailSent: false,
    SignupUsername : "",
    emailreset: "",
    user: null,
    userId : "",
    allbehaviorId: "",
    loading: true,
    email: "",
    password: "",
    showPassword: false,
    emailsignup: "",
    passwordsignup: "",
    confirmPassword: "",
    showConfirmPassword: false,
    showSignupPassword: false,

  };
  

  