export const mission2InitialState = {
  step: 0,
  stakeholders: [
    {
      id: 1,
      Name: "",
      SelectedOption: "",
      SelectedOption2: "",
      behaviors: [
        {
          id: 1,
          Behavior: "",
          biases: [
            {
              id: 1,
              Category: "",
              SubCategory: "",
              Definition: "",
              ResultEast: 0,
              ResultMindspace: 0,
              AssociatedNudgeType: "",
              AssociatedCognitiveBiases: "",
              Description: "",
              Impact: "",
              EffectSize: "",
              MSOP: "",
              Next: "",
              Scale: "",
              DetailedDescription: "",
              Path: "", 
              CongitiveBias : ""

            },
          ],
        },
      ],
      Interactions: [
        {
          id: 1,
          person: "",
          behaviors: [{ id: 1, behavior: "" }],
          selectedOption: "",
        },
      ],
      Messengers: [{ id: 1, name: "", contactInfo: "" }],
      Artifacts: [{ id: 1, artifact: "" }],
    },
  ],
};
