import { createSlice } from "@reduxjs/toolkit";
import { initialInitialState } from "./initialStates";

const initialSlice = createSlice({
  name: "initial",
  initialState: initialInitialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },

    // Premission1 Actions
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setIsModalOpen2: (state, action) => {
      state.isModalOpen2 = action.payload;
    },
    setSelectedOptions1: (state, action) => {
      state.selectedOptions1 = action.payload;
    },
    setSelectedOptions2: (state, action) => {
      state.selectedOptions2 = action.payload;
    },
    setTeam: (state, action) => {
      state.team = action.payload;
    },
    setAllBehaviorId: (state, action) => {
      state.allbehaviorId = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDeadline: (state, action) => {
      state.deadline = action.payload;
    },
    setHours: (state, action) => {
      state.hours = action.payload;
    },
    setBehchange: (state, action) => {
      state.behchange = action.payload;
    },
    setOverview: (state, action) => {
      state.overview = action.payload;
    },
    setSolution: (state, action) => {
      state.solution = action.payload;
    },
    // Mission1 Actions
    setMembers: (state, action) => {
      state.members = action.payload;
    },
  },
});

export const {   setStep,
  setIsModalOpen,
  setIsModalOpen2,
  setSelectedOptions1,
  setSelectedOptions2,
  setTeam,
  setTitle,
  setDeadline,
  setHours,
  overview,
  solution,
  behchange,
  setBehchange,
  setOverview,
  setSolution,
  setMembers,setAllBehaviorId, } = initialSlice.actions;
export const initialReducer =  initialSlice.reducer;
