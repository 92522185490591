import { gql } from "@apollo/client";

const CREATE_USER = gql`
  mutation CreateUser($email: String, $username: String) {
    createUser(email: $email, username: $username) {
      userId
      email
    }
  }
`;
const UPDATE_USER = gql`
mutation UpdateUser($userId: ID, $input: UserInput) {
  updateUser(userId: $userId, input: $input) {
    email
    userId
    username
  }
}
`;
const DELETE_USER = gql`
mutation DeleteUser($userId: ID) {
  deleteUser(userId: $userId)
}
`;

const CREATE_ALL_BEHAVIORS = gql`
  mutation CreateAllBehaviors(
    $userId: ID!
    $allBehaviorsInput: AllBehaviorsInput!
  ) {
    createAllBehaviors(userId: $userId, allBehaviorsInput: $allBehaviorsInput) {
      allBehaviorsId
      SelectedOptions1
      SelectedOptions2
      Team
      Title
      Deadline
      Start
      Hours
      Members
      Overview
      Solution
      BehChange
      SelectedFile1
      SelectedFile2
      SelectedOptions
      DataSources
      EvaluatePull
      EvaluatePath
      Tasks
      Days
      HoursWeek
      TerminationInput {
        Who
        When
        What
      }
      SuccessInput {
        Who
        When
        What
      }
      PeopleDataInput {
        functionality
        hours
        name
        tasks
      }
      SprintWork
      SprintType
      NumberOfPeople
      ismultiple
      UrlNudge
      UrlSprint 

    }
  }
`;

const UPDATE_ALL_BEHAVIORS = gql`
  mutation UpdateAllBehaviors(
    $userId: ID
    $allBehaviorsId: ID
    $input: AllBehaviorsInput
   
  ) {
    updateAllBehaviors(
      userId: $userId
      allBehaviorsId: $allBehaviorsId
      input: $input
    ) {
      SelectedFile1
      UrlNudge
      UrlSprint 
      SelectedFile2
      SelectedOptions
      EvaluatePath
      Tasks
      Days
      HoursWeek
    }
  }
`;
const CREATE_STAKEHOLDER = gql`
  mutation CreateStakeholder(
    $userId: ID!
    $allBehaviorsId: ID!
    $stakeholderInput: StakeholderInput!
  ) {
    createStakeholder(
      userId: $userId
      allBehaviorsId: $allBehaviorsId
      stakeholderInput: $stakeholderInput
    ) {
      id
      stakeholderId
      Name
      SelectedOption
      SelectedOption2
      Interactions {
        id
        person
        selectedOption
        behaviors {
          id
          behavior
        }
      }
      Messengers {
        id
        name
        contactInfo
      }
      Artifacts {
        id
        artifact
      }
    }
  }
`;
const CREATE_BEHAVIOR = gql`
  mutation createBehavior(
    $userId: ID
    $allBehaviorsId: ID
    $stakeholderId: ID
    $behaviorInput: BehaviorInput
  ) {
    createBehavior(
      userId: $userId
      allBehaviorsId: $allBehaviorsId
      stakeholderId: $stakeholderId
      behaviorInput: $behaviorInput
    ) {
      id
      Behavior
      behaviorId
    }
  }
`;

const CREATE_BIAS = gql`
  mutation CreateBias(
    $userId: ID
    $allBehaviorsId: ID
    $stakeholderId: ID
    $behaviorId: ID
    $biasInput: BiasInput
  ) {
    createBias(
      
      userId: $userId
      allBehaviorsId: $allBehaviorsId
      stakeholderId: $stakeholderId
      behaviorId: $behaviorId
      biasInput: $biasInput
    ) {
      id
      biasId
      Category
      SubCategory
      Definition
      ResultEast
      ResultMindspace
      AssociatedNudgeType
      AssociatedCognitiveBiases
      Description
      Impact
      EffectSize
      MSOP
      Next
      Scale
      DetailedDescription
      Path
    }
  }
`;
const UPDATE_BIAS = gql`
  mutation UpdateBias(
    $userId: ID
    $allBehaviorsId: ID
    $stakeholderId: ID
    $behaviorId: ID
    $biasId: ID
    $input: BiasInput
  ) {
    updateBias(
      userId: $userId
      allBehaviorsId: $allBehaviorsId
      stakeholderId: $stakeholderId
      behaviorId: $behaviorId
      biasId: $biasId
      input: $input
    ) {
      biasId
      Category
      SubCategory
      Definition
      ResultEast
      ResultMindspace
      AssociatedNudgeType
      AssociatedCognitiveBiases
      Description
      Impact
      EffectSize
      MSOP
      Next
      Scale
      DetailedDescription
      Path
    }
  }
`;
const UPDATE_STAKEHOLDER = gql`
mutation UpdateStakeholder($userId: ID, $allBehaviorsId: ID, $stakeholderId: ID, $input: StakeholderInput) {
  updateStakeholder(userId: $userId, allBehaviorsId: $allBehaviorsId, stakeholderId: $stakeholderId, input: $input) {
    stakeholderId
    Name
    SelectedOption
    SelectedOption2
    Interactions {
      id
      behaviors {
        behavior
        id
      }
      person
      selectedOption
    }
    Messengers {
      name
      id
      contactInfo
    }
    Artifacts {
      artifact
      id
    }
  }
}
`;
const UPDATE_BEHAVIOR = gql`
mutation UpdateBehavior($userId: ID, $allBehaviorsId: ID, $stakeholderId: ID, $behaviorId: ID, $input: BehaviorInput) {
  updateBehavior(userId: $userId, allBehaviorsId: $allBehaviorsId, stakeholderId: $stakeholderId, behaviorId: $behaviorId, input: $input) {
    Behavior
    behaviorId
  }
}
`;


const DELETE_ALL_BEHAVIOR = gql`
mutation DeleteAllBehaviors($userId: ID, $allBehaviorsId: ID) {
  deleteAllBehaviors(userId: $userId, allBehaviorsId: $allBehaviorsId)
}
`;

const DELETE_STAKEHOLDER = gql`
mutation DeleteStakeholder($userId: ID, $allBehaviorsId: ID, $stakeholderId: ID) {
  deleteStakeholder(userId: $userId, allBehaviorsId: $allBehaviorsId, stakeholderId: $stakeholderId)
}
`;

const DELETE_BEHAVIOR = gql`
mutation DeleteBehavior($userId: ID, $allBehaviorsId: ID, $stakeholderId: ID, $behaviorId: ID) {
  deleteBehavior(userId: $userId, allBehaviorsId: $allBehaviorsId, stakeholderId: $stakeholderId, behaviorId: $behaviorId)
}
`;

const DELETE_BIAS = gql`
mutation DeleteBias($userId: ID, $allBehaviorsId: ID, $stakeholderId: ID, $behaviorId: ID, $biasId: ID) {
  deleteBias(userId: $userId, allBehaviorsId: $allBehaviorsId, stakeholderId: $stakeholderId, behaviorId: $behaviorId, biasId: $biasId)
}
`;

export {
  CREATE_USER,
  CREATE_ALL_BEHAVIORS,
  UPDATE_ALL_BEHAVIORS,
  CREATE_STAKEHOLDER,
  CREATE_BEHAVIOR,
  CREATE_BIAS,
  UPDATE_BIAS,
  DELETE_ALL_BEHAVIOR,
  UPDATE_STAKEHOLDER,
  UPDATE_BEHAVIOR,
  DELETE_STAKEHOLDER,
  DELETE_BEHAVIOR,
  DELETE_BIAS,
  UPDATE_USER,
  DELETE_USER,
};
