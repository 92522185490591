import React from "react";

const imageSources = [
  "https://d35wha680fyhmr.cloudfront.net/bg1.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg2.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg3.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg4.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg5.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg6.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg7.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg8.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg9.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg10.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg11.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg12.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg13.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg14.webp",
  "https://d35wha680fyhmr.cloudfront.net/bg15.webp",
];
const ImageGrid = () => {
  return (
    <div className="min-h-screen flex flex-col relative z-0">
      <div className="flex flex-1">
        {imageSources.slice(0, 5).map((image, index) => (
          <div
            key={index}
            className="flex-1 bg-cover bg-center relative"
            style={{ backgroundImage: `url(${image})`, opacity: 0.5 }}
          />
        ))}
      </div>

      <div className="flex flex-1">
        {imageSources.slice(5, 10).map((image, index) => (
          <div
            key={index}
            className="flex-1 bg-cover bg-center relative"
            style={{ backgroundImage: `url(${image})`, opacity: 0.5 }}
          />
        ))}
      </div>
      {/* Third Row */}
      <div className="flex flex-1">
        {imageSources.slice(10, 15).map((image, index) => (
          <div
            key={index}
            className="flex-1 bg-cover bg-center relative"
            style={{ backgroundImage: `url(${image})`, opacity: 0.5 }}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageGrid;
