// queries.js

import { gql } from "@apollo/client";

const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      userId
      email
      username
    }
  }
`;
const GET_ALL_USERS = gql`
query GetAllUsers {
  getAllUsers {
    email
    userId
    username
  }
}

`;
const GET_ALL_BEHAVIOR = gql`
query GetAllBehaviors($userId: ID) {
  getAllBehaviors(userId: $userId) {
    allBehaviorsId
    SelectedOptions1
    SelectedOptions2
    Team
    Title
    Deadline
    Start
    Hours
    Members
    Overview
    Solution
    BehChange
    SelectedFile1
    SelectedFile2
    SelectedOptions
    TerminationInput {
      Who
      When
      What
    }
    SuccessInput {
      Who
      When
      What
    }
    DataSources
    EvaluatePull
    EvaluatePath
    Tasks
    Days
    HoursWeek
    SprintType
    SprintWork
    NumberOfPeople
    PeopleDataInput {
      name
      functionality
      hours
      tasks
    }
    ismultiple
    Score
    UrlNudge
    UrlSprint
  }
}
`;
const GET_ALL_STAKEHOLDERS = gql`
query QueryGetAllBehaviorsForStakeholder($userId: ID, $allBehaviorsId: ID) {
  getAllStakeholder(userId: $userId, allBehaviorsId: $allBehaviorsId) {
    id
    stakeholderId
    Name
    SelectedOption
    SelectedOption2
    Interactions {
      id
      person
      selectedOption
      behaviors {
        id
        behavior
      }
    }
    
    Messengers {
      id
      name
      contactInfo
    }
    Artifacts {
      artifact
      id
    }
   
  }
}
`;

const GET_ALL_BEHAVIORS = gql`
query QueryGetAllBehaviorsForStakeholder($userId: ID, $allBehaviorsId: ID, $stakeholderId: ID) {
  queryGetAllBehaviorsForStakeholder(userId: $userId, allBehaviorsId: $allBehaviorsId, stakeholderId: $stakeholderId) {
    Behavior
    id
    behaviorId
  }
}
`;
const GET_ALL_BIASES = gql`
query QueryGetAllBehaviorsForStakeholder($userId: ID, $allBehaviorsId: ID, $stakeholderId: ID, $behaviorId: ID) {
  queryGetAllBiasesForBehavior(userId: $userId, allBehaviorsId: $allBehaviorsId, stakeholderId: $stakeholderId, behaviorId: $behaviorId) {
    id
    biasId
    Category
    SubCategory
    Definition
    ResultEast
    ResultMindspace
    AssociatedNudgeType
    AssociatedCognitiveBiases
    Description
    Impact
    EffectSize
    MSOP
    Next
    Scale
    DetailedDescription
    Path
  }
}
`;



const GET_ONE_ALL_BEHAVIOR = gql`
  query getOneAllBehaviors($userId: ID, $allBehaviorsId: ID) {
    getOneAllBehaviors(userId: $userId, allBehaviorsId: $allBehaviorsId) {
      allBehaviorsId
      SelectedOptions1
      SelectedOptions2
      Team
      Title
      Deadline
      Start
      Hours
      Members
      Score
      Overview
      Solution
      BehChange
      SelectedFile1
      SelectedFile2
      SelectedOptions
      TerminationInput {
        Who
        When
        What
      }
      SuccessInput {
        Who
        When
        What
      }
      DataSources
      EvaluatePull
      EvaluatePath
      Tasks
      Days 
      HoursWeek 
      PeopleDataInput {
        functionality
        hours
        name
        tasks
      }
      SprintWork
      SprintType
      NumberOfPeople
      ismultiple
      UrlNudge
      UrlSprint 
    }
  }
`;


export { GET_ALL_USERS,GET_USER_BY_EMAIL, GET_ALL_BEHAVIOR, GET_ONE_ALL_BEHAVIOR , GET_ALL_STAKEHOLDERS, GET_ALL_BEHAVIORS,GET_ALL_BIASES};
