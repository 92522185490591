import { createSlice } from "@reduxjs/toolkit";
import { mission1InitialState } from "./initialStates";

const mission1Slice = createSlice({
  name: "mission1",
  initialState: mission1InitialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setSelectedFile1: (state, action) => {
      state.selectedFile1 = action.payload;
    },
    setSelectedFile2: (state, action) => {
      state.selectedFile2 = action.payload;
    },
    setSelectedOptions: (state, action) => {
      state.selectedOptions = action.payload;
    },
  },
});

export const { setStep, setSelectedFile1, setSelectedFile2,setSelectedOptions } = mission1Slice.actions;
export const mission1Reducer =  mission1Slice.reducer;
