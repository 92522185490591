import React from "react";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1171BA",
    },
    secondary: {
      main: "#AFAEB2",
    },
  },
});

const Footer = ({ value }) => {
  var customSteps = [];
  const isSprintBlueprint = value === "sprint";

  if (!isSprintBlueprint) {
    customSteps = [
      {
        node: "Start",
        nodeColor: theme.palette.primary.main,
        name: "INITAL STEPS",
      },
      { node: "1", nodeColor: theme.palette.primary.main, name: "" },
      { node: "2", nodeColor: theme.palette.primary.main, name: "" },
      { node: "3", nodeColor: theme.palette.primary.main, name: "" },
      {
        node: "4",
        nodeColor: theme.palette.secondary.main,
        name: "AGILE NUDGE BLUEPRINT",
      },
      { node: "5", nodeColor: theme.palette.secondary.main, name: "" },
      { node: "6", nodeColor: theme.palette.secondary.main, name: "" },
    
      {
        node: "End",
        nodeColor: theme.palette.secondary.main,
        name: "AGILE SPRINT BLUEPRINT",
      },
  
  
    

    
   
    ];
  } else {
    customSteps = [
      {
        node: "Start",
        nodeColor: theme.palette.primary.main,
        name: "INITAL STEPS",
      },
      { node: "1", nodeColor: theme.palette.primary.main, name: "" },
      { node: "2", nodeColor: theme.palette.primary.main, name: "" },
      { node: "3", nodeColor: theme.palette.primary.main, name: "" },
      {
        node: "4",
        nodeColor: theme.palette.primary.main,
        name: "AGILE NUDGE BLUEPRINT",
      },
      { node: "5", nodeColor: theme.palette.primary.main, name: "" },
      { node: "6", nodeColor: theme.palette.primary.main, name: "" },
      {
        node: "End",
        nodeColor: theme.palette.primary.main,
        name: "AGILE SPRINT BLUEPRINT",
      },
     
    ];
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="w-[23%] xxl:w-[20%] flex flex-row   bg-white  shadow-inner text-xs pt-5 shadow-lg">
        <div className="w-[15%]">
          <div className="relative ml-20">
            <div
              className="absolute w-[500px] h-1 origin-top-left rotate-90  rounded-[20px] opacity-70"
              style={{
                background: `linear-gradient(to right, ${
                  isSprintBlueprint
                    ? theme.palette.primary.main + " 1000px, "
                    : theme.palette.primary.main + " 270px, "
                }${theme.palette.secondary.main} 270px, ${
                  theme.palette.secondary.main
                } 100%)`,
              }}
            ></div>

            <div className="flex flex-col absolute left-1/2 lg:w-1/6 w-4/5 -translate-x-1/2 lg:pr-[70px] justify-between space-y-8 mx-4">
              {customSteps.map((step, index) => (
                <div
                  key={step.node}
                  className={`flex items-center justify-center w-[35px] h-[35px] text-white text-xs font-normal rounded-full`}
                  style={{
                    backgroundColor: step.nodeColor,
                  }}
                >
                  {step.node}
                </div>
              ))}
            </div>

            <div className="flex flex-col  w-[120px]  h-[490px] justify-between ml-10 ">
              {customSteps.map((step, index) => (
                <div
                  key={step.node}
                  className={`mt-2 w-[120px]  opacity-70  text-[9px] font-normal ${
                    step.name === "AGILE SPRINT BLUEPRINT" && isSprintBlueprint
                      ? "text-primary font-semibold text-opacity-100"
                      : step.name === "AGILE NUDGE BLUEPRINT" &&
                        !isSprintBlueprint
                      ? "text-primary font-semibold"
                      : ""
                  }`}
                >
                  {step.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Footer;
