import { createSlice } from "@reduxjs/toolkit";
import { mission4InitialState } from "./initialStates";

const mission4Slice = createSlice({
  name: "mission4",
  initialState: mission4InitialState,
  reducers: {
    setDedicatedHours: (state, action) => {
      state.dedicatedHours = action.payload;
    },
    setSprintDuration: (state, action) => {
      state.sprintDuration = action.payload;
    },
    setEvaluatePath: (state, action) => {
      state.EvaluatePath = action.payload;
    },
    setTasks: (state, action) => {
      state.Tasks = action.payload;
    },
    setSprintWork: (state, action) => {
      state.SprintWork = action.payload;
    },
    setSprintType: (state, action) => {
      state.SprintType = action.payload;
    },
    setNumberOfPeople: (state, action) => {
      state.NumberOfPeople = action.payload;
    },
    setHours: (state, action) => {
      state.hours = action.payload;
    },
    setPeopleData: (state, action) => {
      state.peopleData = action.payload;
    },
  },
});

export const {
  setDedicatedHours,
  setSprintDuration,
  setEvaluatePath,
  setTasks,
  setSprintWork,
  setSprintType,
  setNumberOfPeople,
  setHours,
  setPeopleData,
} = mission4Slice.actions;
export const mission4Reducer = mission4Slice.reducer;
