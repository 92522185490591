export const initialInitialState = {
  step: 0,
  isModalOpen: false,
  isModalOpen2: false,
  selectedOptions1: [],
  selectedOptions2: [],
  allbehaviorId : "",
  team: "",
  title: "",
  deadline: "",
  hours: "",
  members: 0,
  overview: "",
  solution: "",
  behchange: "",
};
