import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import Store from './Store/Store';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
export const client = new ApolloClient({
  uri: "https://agilenudgeplus1-dot-ind-iusm-chiis-sw-dev.uc.r.appspot.com/graphql",
  cache: new InMemoryCache(),
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
 <ApolloProvider client={client}>
    <Provider store={Store}>
      
    <App />
    </Provider>
    </ApolloProvider>
  </BrowserRouter>
);

reportWebVitals();
