export const mission3InitialState = {
  step: 0,
  termination: {
    Who: "",
    When: "",
    What: "",
  },
  success: {
    Who: "",
    When: "",
    What: "",
  },
  };
  