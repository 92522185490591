import { NavLink } from "react-router-dom";
import ImageGrid from "./Imagegrid";

import React, { useState } from "react";

const Behaviors = () => {
  const [step, setStep] = useState(0);
  const handleContinue = () => {
    setStep(step + 1);
  };

  return (
    <div className="flex flex-col h-screen">
      {step === 0 && (
        <div className="min-h-screen flex flex-col relative bg-primary">
          <ImageGrid />
          <div className="absolute inset-0 flex items-center justify-center text-white text-4xl font-bold z-10 bg-primary bg-opacity-[80%]">
            <div className="flex flex-col space-y-5 h-screen overflow-scroll w-screen scrollbar-hide justify-center items-center ">
              <div className="flex flex-row space-x-3 font-primary text-white text-[74px] mb-8">
                <h6 className="p-3 font-black-ops-one">AgileNudge</h6>
                <h1 className="font-black text-[80px]">+</h1>
              </div>
              <div className="w-[576px] h-[91px] text-primary bg-white rounded-[36px] flex items-center justify-center text-primary text-3xl font-extrabold font-roboto ">
                <button onClick={handleContinue}>
                  Make a Change with a Nudge
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 1 && (
        <div className="flex flex-col h-screen overflow-scroll w-screen scrollbar-hide justify-end items-center font-roboto ">
          <div className="flex justify-start p-20 w-screen  text-white lg:text-[40px]  md:text-[30px] sm:text-[25px] xs:text-[20px] font-semibold font-roboto leading-[74px]">
            AgileNudge+ is divided into three key sections...
          </div>

          <div className="w-screen flex justify-end pb-20 pr-20">
            <button
              onClick={handleContinue}
              className="w-[150px] h-[45px] bg-white rounded-[36px] flex items-center justify-center text-black text-base font-bold font-roboto"
            >
              Next
            </button>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="flex flex-col h-screen overflow-scroll w-screen scrollbar-hide justify-end items-center font-roboto ">
          <div className="flex justify-start p-20 w-screen  text-white lg:text-3xl md:text-[30px] sm:text-[25px] xs:text-[20px] font-bold font-roboto leading-[74px]">
            In the BEHAVIORS section, embark on a journey of self-empowerment,
            <br />
            <br />
            Plan to change behaviors through two steps: Nudge and Sprint. <br />
            <br />
            Answer questions, fill in the boxes, and get powerful blueprints for
            lasting transformation <br />
            <br />
            after 6 missions!
          </div>

          <div className="w-screen flex justify-end pb-20 pr-20">
            <button
              onClick={handleContinue}
              className="w-[150px] h-[45px] bg-white rounded-[36px] flex items-center justify-center text-black text-base font-bold font-roboto"
            >
              Next
            </button>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="flex flex-col h-screen overflow-scroll w-screen scrollbar-hide justify-end items-center font-roboto ">
          <div className="flex justify-start p-20 w-screen  text-white lg:text-3xl md:text-[30px] sm:text-[25px] xs:text-[20px] font-semibold font-roboto leading-[74px]">
            In the GROWTH section, quench your thirst for knowledge! Access
            diverse sources,
            <br />
            <br /> develop skills, and stay informed about important events and
            bootcamps!
          </div>

          <div className="w-screen flex justify-end pb-20 pr-20">
            <button
              onClick={handleContinue}
              className="w-[150px] h-[45px] bg-white rounded-[36px] flex items-center justify-center text-black text-base font-bold font-roboto"
            >
              Next
            </button>
          </div>
        </div>
      )}
      {step === 4 && (
        <div className="flex flex-col h-screen overflow-scroll w-screen scrollbar-hide justify-end items-center font-roboto ">
          <div className="flex justify-start p-20 w-screen  text-white lg:text-3xl md:text-[30px] sm:text-[25px] xs:text-[20px] font-semibold font-roboto leading-[74px]">
            In the NETWORK section, Join groups, diffuse ideas, seek support,
            and thrive in our <br /> <br />
            supportive learning community.
          </div>

          <div className="w-screen flex justify-end pb-20 pr-20">
            <NavLink
              to="/login"
              className="w-[150px] h-[45px] bg-white rounded-[36px] flex items-center justify-center text-black text-base font-bold font-roboto"
            >
              Next
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default Behaviors;
