import {
  GET_ALL_STAKEHOLDERS,
  GET_ALL_BEHAVIORS,
  GET_ALL_BIASES,
} from "../Graphql/User/Query";

const fetchNestedData = async ({ userId, allBehaviorsId, fetchMore }) => {
  try {
    const { data: stakeholdersData } = await fetchMore({
      query: GET_ALL_STAKEHOLDERS,
      variables: { userId, allBehaviorsId },
    });

    const stakeholders = stakeholdersData?.getAllStakeholder || [];

    const nestedDataPromises = stakeholders.map(async (stakeholder) => {
      const { data: behaviorsData } = await fetchMore({
        query: GET_ALL_BEHAVIORS,
        variables: {
          userId,
          allBehaviorsId,
          stakeholderId: stakeholder.stakeholderId,
        },
      });

      const behaviors = behaviorsData?.queryGetAllBehaviorsForStakeholder || [];

      const filteredBehaviors = behaviors
        .filter((behavior) => behavior.Behavior !== null)
        .map(async (behavior) => {
          const { data: biasesData } = await fetchMore({
            query: GET_ALL_BIASES,
            variables: {
              userId,
              allBehaviorsId,
              stakeholderId: stakeholder.stakeholderId,
              behaviorId: behavior.behaviorId,
            },
          });

          const biases = biasesData?.queryGetAllBiasesForBehavior || [];

          const filteredBiases = biases.filter(
            (bias) => bias.Category !== null
          );

          return {
            behaviorId: behavior.behaviorId,
            Behavior: behavior.Behavior,
            id: behavior.id,
            biases: filteredBiases,
          };
        });

      const behaviorsResults = await Promise.all(filteredBehaviors);

      return {
        stakeholderId: stakeholder.stakeholderId,
        Name: stakeholder.Name,
        Messengers: stakeholder.Messengers,
        id: stakeholder.id,
        Interactions: stakeholder.Interactions,
        Artifacts: stakeholder.Artifacts,
        SelectedOption2: stakeholder.SelectedOption2,
        behaviors: behaviorsResults.filter(
          (behavior) => behavior.Behavior !== null
        ),
      };
    });

    const nestedDataResults = await Promise.all(nestedDataPromises);

    return nestedDataResults.filter((stakeholder) => stakeholder.Name !== null);
  } catch (error) {
    alert("Error fetching nested data:", error.message);
    return [];
  }
};

export default fetchNestedData;
