import React, { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { Outlet } from "react-router-dom";
import Landing from "./Landing";
import { connect } from "react-redux";
import { setUser, setLoading, setUserId } from "../Store/Common/Slice";
import { GET_USER_BY_EMAIL } from "../Graphql/User/Query";

import { useQuery} from "@apollo/client";
const Loggedin = ({ user, loading, setUser, setLoading,setUserId }) => {
  const { loading: queryLoading, data: userData  } = useQuery(GET_USER_BY_EMAIL, {
    variables: { email: user || "" },
  });
  console.log(userData);
  if(localStorage.getItem("score") === null)
  {
    localStorage.setItem("score","0" );
    
  }
  console.log(localStorage.getItem("email"))
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      console.log(authUser)
      if (authUser) {
        if(localStorage.getItem("from") === "true") {
          console.log("HI")
          setUser("vrushab1998@gmail.com");
        
        }  
        else{
          console.log("Hello")
          setUser(authUser.email);
          localStorage.setItem("uid", authUser.uid);
        }
       localStorage.setItem("from", "false")
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if (!queryLoading && userData && userData.getUserByEmail) {
      const userId = userData.getUserByEmail.userId;
      setUserId(userId);
      localStorage.setItem("userId", userId);
      localStorage.setItem("email", userData.getUserByEmail.email);
    }
  }, [queryLoading, setUserId, userData]);
  if (loading || queryLoading) {
    return (
      <div className="w-screen h-screen bg-primary flex justify-center items-center text-4xl text-white">
        Loading...
      </div>
    );
  }

  return <div>{user ? <Outlet /> : <Landing />}</div>;
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

const mapDispatchToProps = {
  setUser,
  setLoading,
  setUserId
};

export default connect(mapStateToProps, mapDispatchToProps)(Loggedin);
